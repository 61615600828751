<template>
  <gl-modal
    v-bind="$attrs"
    capitalize-title
    full-buttons
    submit-title="Delete"
    title="Delete from monitoring"
    width="600"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit"
  >
    <div class="gl-modal__row">
      Are you sure you want to delete this address <strong class="word-break">{{ address }}</strong> from the Monitoring?
    </div>
  </gl-modal>
</template>

<script>
import GlModal from '@/components/gl-modal'

export default {
  components: {
    GlModal,
  },
  inheritAttrs: false,
  props: {
    address: {
      type: String,
      default: '',
      require: true
    },
  },
  methods: {
    onSubmit() {
      this.$emit('submit', this.address)
    },
  },
}
</script>