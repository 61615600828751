<template>
  <gl-modal
    v-bind="$attrs"
    full-buttons
    :loading="loading"
    :side-valid="(address.length === 0 && activeTab === 'single') || (!file && activeTab === 'bulk')"
    submit-title="Add"
    title="Add To Monitoring"
    v-on="$listeners"
    @close="$emit('close')"
    @submit="onSubmit($event)"
  >
    <div class="flex align-end space-around mb-5 scoring-tabs-wrap">
      <div
        class="tab m-flex-1 mr-4"
        :class="{'tab-active': activeTab === 'single'}"
        @click="handleActiveTab('single')"
      >
        Single address
      </div>
      <div
        class="tab m-flex-1"
        :class="{'tab-active': activeTab === 'bulk'}"
        @click="handleActiveTab('bulk')"
      >
        Bulk upload
      </div>
    </div>
    <GlCoinSelect
      class="mb-4"
      dark
      label="blockchain"
    />
    <div
      v-if="activeTab === 'single'"
      class="mb-4"
    >
      <GlInput
        v-model.trim="address"
        :disabled="loading"
        :height="40"
        label="address"
        placeholder="Enter the address"
        rules="required"
      />
    </div>
    <div
      v-else
      class="mb-4 flex space-between"
    >
      <gl-menu-item
        v-if="!file"
        class="fs-14 mb-4"
        :disabled="loading"
        icon="import"
        :icon-height="24"
        :icon-width="24"
        label="Upload CSV"
        @click="openFileInput"
      />
      <gl-menu-item
        v-else
        class="fs-14 mb-4"
        :disabled="loading"
        icon="file"
        :icon-height="24"
        :icon-width="24"
        :label="file.name || ''"
      />
      <gl-menu-item
        v-if="file"
        :disabled="loading"
        icon="delete-action"
        :icon-height="24"
        :icon-width="24"
        @click="clearFile"
      />
      <input
        ref="addressMonitoringUploader"
        accept=".csv"
        :disabled="loading"
        :style="{display: 'none'}"
        type="file"
        @input="onFileChange"
      >
    </div>
  </gl-modal>
</template>

<script>
// Components
import GlModal from '@/components/gl-modal'
import GlInput from '@/components/gl-input'
import GlMenuItem from '@/components/gl-menu-item'
import GlCoinSelect from "@/components/gl-coin-select.vue";

// Vuex
import { mapActions, mapState } from "vuex";

// Utils
import apiService from "@/utils/api-client";
import {isExceptionStatus} from "@/utils/accesses";

export default {
  components: {
    GlModal,
    GlMenuItem,
    GlCoinSelect,
    GlInput
  },
  inheritAttrs: false,
  data() {
    return {
      address: '',
      file: '',
      formData: {},
      activeTab: 'single',
      checkLastTen: false,
      loading: false,
    }
  },
  computed: {
    ...mapState('analytics', ['coinData'])
  },
  methods: {
    ...mapActions({
      addToMonitoring: 'monitoring/addToMonitoring',
    }),
    handleActiveTab(tab) {
      if (this.loading) return
      this.activeTab = tab
      this.address = ''
    },
    openFileInput() {
      this.$refs.addressMonitoringUploader.click()
    },
    onFileChange(e) {
      if (this.loading) return
      this.file = e.target.files[0]

      this.formData = new FormData()

      this.formData.append('file', this.file);
      this.formData.append('blockchain', this.coinData.label);
    },
    clearFile() {
      this.file = null
      this.formData = null
    },
    onSubmit() {
      if (this.address || this.file) {
        this.loading = true
        if (this.activeTab === 'single') {
          this.addToMonitoring({
            address: this.address,
            checkLastTen: this.checkLastTen,
            blockchain: this.coinData.label
          }).then(({ success, data }) => {
            if (success) {
              this.$emit('add')
              this.$emit('close')
            }
            else if (!isExceptionStatus(data.status)) {
              this.$toasted.global.error({message: `${data.message}`})
            }
          }).catch(({ response: { data } }) => {
              this.$toasted.global.error({message: `${data.data.message}`})
          }).finally(() => this.loading = false)
        } else {
          apiService.post( '/monitoring/addressToMonitor',
              this.formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              }
          ).then(({ success, data }) => {
            if (success) {
              this.$emit('add-bulk', data)
              this.$emit('close')
            }
            else {
              this.$toasted.global.error({message: `${data.message}`})
            }
          }).finally(() => this.loading = false)
        }
      }
    },
  },
}
</script>