<template>
  <GlPageWrap
    class="monitoring-page-wrapper"
    title="Monitoring"
  >
    <template #actions>
      <div class="m-pa-2 m-fixed-bottom-wrapper">
        <GlButton
          class="m-fullwidth"
          dark
          :style="[{height: '40px'}]"
          title="add to monitoring"
          @click="showAddModal = true"
        />
      </div>
    </template>
    <div class="table-wrap table-wrap__unoverflow gl-table-unshadow">
      <div class="px-3 pt-3">
        <div class="flex m-column">
          <GlCoinSelect
            class="m-mb-3 "
            dark
            @change="loadData"
          />
          <gl-search-box
            v-model="address"
            class="search-box mb-2 fullwidth"
            dark-clear
            :disabled="loading || !address"
            grey
            placeholder="Enter the address"
            tagging
            @clear="clearSearching"
            @search="searching"
          />
        </div>
      </div>
      <o-table
        class="table__overflow-auto"
        :class="{ 'o-table-has-pagination': addressData.length && totalPages > 1 }"
        :data="addressData"
        hoverable
        :loading="loading"
        :mobile-cards="false"
      >
        <o-table-column
          v-slot="props"
          field="riskScore"
          label="Score"
        >
          <div class="flex align-center">
            <div
              v-if="!props.row.txCount"
              class="px-3"
            >
              --
            </div>
            <GlSpiner
              v-else-if="props.row.riskScore === undefined || props.row.riskScore === null"
            />
            <GlScoreLevelChip
              v-else
              class="mr-3"
              :level="props.row.riskScore ? Math.ceil(props.row.riskScore) : 0"
              score
              style="min-width: 55px"
            />
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="address"
          label="address"
        >
          <div
            class="link"
            @click="openInNewTab(props.row.address)"
          >
            {{ trancateString(props.row.address, 8) }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="firstSeen"
          label="First seen"
        >
          <div class="min-w-150">
            {{ props.row.firstSeen ? formatDate(props.row.firstSeen * 1000, 'dd.MM.yyyy HH:mm') : '-' }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="updatedAt"
          label="Last seen"
        >
          <div class="min-w-150">
            {{ props.row.lastSeen ? formatDate(props.row.lastSeen * 1000, 'dd.MM.yyyy HH:mm') : '-' }}
          </div>
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="txCount"
          label="Number of txs"
        >
          {{ props.row.txCount || '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="balance"
          label="balance"
        >
          {{ props.row.balance && props.row.balance.amount ? restrictNumberAfterComma(props.row.balance.amount, 8) : '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="amountReceived"
          label="Amount received"
          :visible="!$can('use','eth')"
        >
          {{ props.row.amountReceived ? formatBtcAmount(props.row.amountReceived, false, coinData.family, false, true) : '-' }}
        </o-table-column>
        <o-table-column
          v-slot="props"
          field="balance"
          label=""
        >
          <div class="flex">
            <gl-icon
              v-if="featureAccess('REPORT')"
              v-tooltip.top="'Risk report'"
              class="pointer mr-2"
              :height="24"
              name="additional-open"
              :width="24"
              @click="openInNewTab(props.row.address, 'report')"
            />
            <gl-menu-item
              v-tooltip.top="'Delete address'"
              icon="delete"
              :icon-height="24"
              :icon-width="24"
              warn
              @click="setRemoveAddress(props.row.address)"
            />
          </div>
        </o-table-column>
        <template #empty>
          <div
            v-if="loading"
            class="flex align-center justify-center"
          >
            <gl-loader />
          </div>
          <div
            v-else
            class="empty-users-data flex column align-center"
          >
            <gl-icon
              :height="24"
              name="statistic"
              :width="24"
            />
            No data here yet
          </div>
        </template>
      </o-table>
      <div class="flex space-between pa-2">
        <div class="flex align-center m-column pa-3 m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 m-fs-12 bold m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            @input="countChange"
          />
        </div>
        <o-pagination
          v-if="addressData.length && totalPages > 1"
          class="stat-pagination"
          :current.sync="currentPage"
          order="right"
          :per-page="perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
    <AddToMonitoringModal
      v-if="showAddModal"
      v-model="showAddModal"
      @add="handleAddNewAddress"
      @add-bulk="uploadBulkHandler"
      @close="showAddModal = false"
    />
    <BulkUploadResult
      v-if="showAddBulkModal"
      v-model="showAddBulkModal"
      :data="addBulkData"
      @close="showAddBulkModal = false"
    />
    <StopMonitoringModal
      v-if="stopMonitoringModal"
      v-model="stopMonitoringModal"
      :address="removedAddress"
      @close="stopMonitoringModal = false"
      @submit="removeAddress"
    />
  </GlPageWrap>
</template>

<script>
// Components
import vSelect from 'vue-select';
import GlIcon from '@/components/gl-icon';
import GlLoader from '@/components/gl-loader';
import GlSpiner from '@/components/gl-spiner';
import GlButton from "@/components/gl-button";
import GlMenuItem from '@/components/gl-menu-item';
import GlSearchBox from "@/components/gl-search-box";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import GlCoinSelect from "@/components/gl-coin-select.vue";
import GlScoreLevelChip from '@/components/gl-score-level-chip';
import BulkUploadResult from "@/pages/monitoring/modals/BulkUploadResult";
import StopMonitoringModal from "@/pages/monitoring/modals/StopMonitoringModal";
import AddToMonitoringModal from "@/pages/monitoring/modals/AddToMonitoringModal";

// Utils
import { formatDate } from "@/utils/format-date";
import { featureAccess } from "@/utils/accesses";
import { trancateString } from '@/utils/text-formatter';
import { formatBtcAmount } from '@/utils/format-btc-amount';
import { restrictNumberAfterComma } from '@/utils/formatNumber';

// Vuex
import { mapActions, mapState } from "vuex";

export default {
  components: {
    GlIcon,
    vSelect,
    GlButton,
    GlLoader,
    GlSpiner,
    GlPageWrap,
    GlMenuItem,
    GlSearchBox,
    GlCoinSelect,
    GlScoreLevelChip,
    BulkUploadResult,
    StopMonitoringModal,
    AddToMonitoringModal,
  },
  data() {
    return {
      address: '',
      enableSearchParams: false,
      showAddModal: false,
      showAddBulkModal: false,
      addBulkData: null,
      loading: false,
      pagesOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      removedAddress: '',
      stopMonitoringModal: false,
      total: 1,
      addressData: [],
      restoreAddressData: [],
      monitoringCalculateHandler: null,
      addressesCalculateHandler: null,
      counter: {}
    }
  },
  computed: {
      ...mapState('analytics', ['coinData', 'coinType'])
  },
  created() {
    this.monitoringGettingInterval(100)
  },
  beforeDestroy() {
    clearTimeout(this.monitoringCalculateHandler);
    clearTimeout(this.addressesCalculateHandler);
  },
  beforeRouteLeave(from, to, next) {
    clearTimeout(this.monitoringCalculateHandler);
    clearTimeout(this.addressesCalculateHandler);
    next()
  },
  methods: {
    featureAccess,
    trancateString,
    formatBtcAmount,
    formatDate,
    restrictNumberAfterComma,
    ...mapActions({
      getAddressesMonitoring: 'monitoring/getListSubscriptions',
      getAddressesMonitoringScore: 'monitoring/getAddressesMonitoring',
      deleteAddressFromMonitoring: 'monitoring/deleteAddressFromMonitoring',
    }),
    searching() {
      this.enableSearchParams = true
      this.loadData()
    },
    clearSearching() {
      this.enableSearchParams = false
      this.loadData()
    },
    monitoringGettingInterval(interval = 12000) {
      this.monitoringCalculateHandler = setTimeout(() => {
        this.loadData(false).finally(() => this.monitoringGettingInterval())
      }, interval);
    },
    getScoreInterval(item) {
      this.addressesCalculateHandler = setTimeout(() => {
        this.getScoreByAddress(item)
      }, 5000);
    },
    setRemoveAddress(address) {
      this.removedAddress = address
      this.stopMonitoringModal = true
    },
    removeAddress(address) {
      if (address) {
        this.deleteAddressFromMonitoring({ address }).then(({ success }) => {
          if (success) {
            this.stopMonitoringModal = false
            this.loadData()
          }
        }).catch(({ response: { data } }) => {
          this.$toasted.global.error({message: `${data.data.message}`})
        })
      }
    },
    handleAddNewAddress() {
      this.currentPage = 1
      this.loadData()
    },
    async loadData(isNeedLoading = true) {
      if (isNeedLoading) {
        this.loading = true
      }
      const sendParams = this.formattedSendData()
      await this.getAddressesMonitoring(sendParams).then(({ data, success }) => {
        if (!success) {
            this.loading = false
            clearTimeout(this.monitoringCalculateHandler);
        }

        if (Array.isArray(data.list)) {
          this.addressData = data.list.map((item) => {
            const findIndex = this.restoreAddressData.findIndex(el => el.address === item.address)
            if (findIndex === -1) {
              return item
            } else {
              return {
                ...item,
                riskScore: this.restoreAddressData[findIndex].riskScore
              }
            }
          })
        } else {
          this.addressData = []
        }

        this.total = data.totalItems || 0
        this.totalPages = Math.ceil(this.total / this.perPage)

        const requestedAddress = this.addressData.map(async (item) => {
          this.getScoreByAddress(item)
        })

        Promise.allSettled(requestedAddress)

        this.loading = false
      })
    },
    getScoreByAddress(item) {
      this.getAddressesMonitoringScore({ address: item.address }).then(({ data }) => {
        this.setRestoringData(data.list[0])

        const index = this.addressData.findIndex(el => el.address === data.list[0].address);

        if (index !== -1) {
          this.addressData[index].riskScore = data.list[0].riskScore;
          this.addressData = [...this.addressData];
        }

        if (data.list[0].riskScore === undefined || this.addressData[index].riskScore !== data.list[0].riskScore) {
          this.addressData[index].loading = true
          this.getScoreInterval(item)
        } else {
          this.addressData[index].loading = false
        }
      })
    },
    setRestoringData(data) {
      if (data.address) {
        const index = this.restoreAddressData.findIndex(item => item.address === data.address)

        if (index === -1) {
          this.restoreAddressData.push(data)
        } else if (this.restoreAddressData[index].riskScore !== data.riskScore || this.restoreAddressData[index].txCount !== data.txCount) {
          this.restoreAddressData[index] = data
        }
      }
    },
    formattedSendData() {
      if (this.total <= (this.currentPage - 1) * this.perPage && this.currentPage !== 1) {
        this.currentPage = this.currentPage - 1
      }

      return {
        count: this.perPage,
        address: this.enableSearchParams ? this.address : undefined,
        skip: (this.currentPage - 1) * this.perPage,
      }
    },
    uploadBulkHandler(data) {
      this.currentPage = 1
      this.addBulkData = data
      this.showAddBulkModal = true
      this.loadData()
    },
    openInNewTab(val, routeName = 'analytics') {
      localStorage.removeItem('caseId')
      const { href } = this.$router.resolve({ name: routeName, query: { address: val, type: this.coinData.key } })
      window.open(href, '_blank')
    },
    countChange() {
      this.loadData()
    },
    pageChange(event) {
      this.currentPage = event
      this.loadData()
    },
  },
}
</script>

<style>
.table-wrap .o-table .o-table__th {
  font-size: 12px;
}

.table-wrap .o-table .o-table__td {
  font-size: 15px;
}

.change-page {
  border: 1px solid var(--dark-grey-d-3);
  border-radius: 3px;
  height: 35px;
  padding-top: 5px;
}

.stat-select {
  width: 160px;
}

.statistic-table .o-table .o-table__th {
  font-size: 12px;
}

@media (max-width: 767px) {
  .monitoring-page-wrapper {
    margin-bottom: 60px;
  }
}
</style>

